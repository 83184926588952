@import url(https://fonts.googleapis.com/css2?family=Alef&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*!* Location of the image *!*/
  /*background-image: url("assets/bg.jpg");*/

  /*!* Background image is centered vertically and horizontally at all times *!*/
  /*background-position: center center;*/

  /*!* Background image doesn't tile *!*/
  /*background-repeat: no-repeat;*/

  /*!* Background image is fixed in the viewport so that it doesn't move when*/
  /*   the content's height is greater than the image's height *!*/
  /*background-attachment: fixed;*/

  /*!* This is what makes the background image rescale based*/
  /*   on the container's size *!*/
  /*background-size: cover;*/

  /*!* Set a background color that will be displayed*/
  /*   while the background image is loading *!*/
  /*background-color: #464646;*/
  background-image: linear-gradient(to right top, #d16ba5, #d570a9, #d975ad, #de7ab1, #e27fb5, #e77eba, #ec7dc0, #f17cc6, #f674d1, #fa6ddd, #fb65ea, #fb5ff9);        /*background-image: url("assets/bg.jpg");*/
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    font-family: 'Alef', sans-serif;
}

.app-container {
    min-height: 650px;
    overflow-x: auto;
}

.logo-image{
    width: 220px;
}

.shabbat-times-container {
    position: absolute;
    top: 20%;
    left: 15%;
}

.logo-container {
    position: absolute;
    top: 13%;
    right: 23%;
    text-align: center;
}

.download-android {
    color: white;
}

.bottom-container {
    position: absolute;
    bottom: 8%;
    text-align: center;
    width: 100%;
}

.hebdate {
    font-size: 3vw;
    color: #ffdec1;
}

.todayDateTitle {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 15px;
}

.gregDate {
    font-size: 2.4vw;
    position: absolute;
    right: 40%;
    color: white;
}

@media only screen and (max-height: 500px) {
    .bottom-container {
        position: fixed;
        top: 3%;
        right: 33%;
    }
}

@media only screen and (max-width: 768px) {
    .shabbat-times-container {
        position: static;
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 40px;
    }

    .logo-image {
        width: 100px;
    }

    .logo-container {
        position: static;
        text-align: center;
        display: inline-block;
        width: 100%;
        margin-bottom: 50px;
    }

    .download-android {
        color: white;
    }

    .bottom-container {
        position: static;
        /*bottom: 8%;*/
        text-align: center;
        width: 100%;
    }

    .hebdate {
        font-size: 6vw;
        color: #fcda70;
    }

    .gregDate {
        font-size: 5vw;
        position: absolute;
        right: 40%;
        color: white;
    }
    .bottomMenu {
        visibility: hidden;
    }
    body {
        background-image: linear-gradient(to right top, #d16ba5, #d570a9, #d975ad, #de7ab1, #e27fb5, #e77eba, #ec7dc0, #f17cc6, #f674d1, #fa6ddd, #fb65ea, #fb5ff9);        /*background-image: url("assets/bg.jpg");*/

        /*background-position: center center;*/

        /*background-repeat: no-repeat;*/

        /*background-attachment: fixed;*/

        /*background-size: auto;*/
    }
}

.cal-container {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center !important;
}
.title {
    font-size: 3.1vw;
    font-weight: bold;
    color: white;
    position: relative;
    top: 0.3vw;
    right: 0.5vw;
    margin-bottom: 2.7vw;
}

.parasha {
    font-size: 4.1vw;
    font-weight: bold;
    color: #fcda70;
    margin-bottom: 3vw;
}

.time-header {
    color: white;
    position: relative;
    right: 16vw;
}

.time-header-entrance {
    display: inline-block;
    width: 25%;
}

.time-header-exit {
    display: inline-block;
    width: 25%;
}

.time {
    color: white;
    font-size: 1.2vw;
    position: relative;
    right: 2vw;
    margin-bottom: 1vw;
}

.city {
    display: inline-block;
    width: 31%;
    font-weight: bold;
}

.entrance {
    display: inline-block;
    width: 25%;

}

.exit {
    display: inline-block;
    width: 25%;
}

@media only screen and (max-width: 768px) {
    .title {
        font-size: 7vw;
        font-weight: bold;
        color: white;
        position: relative;
        top: 0.3vw;
        right: 0.7vw;
        margin-bottom: 4vw;
    }

    .parasha {
        font-size: 9vw;
        font-weight: bold;
        color: #fcda70;
        margin-bottom: 8vw;
    }

    .time-header {
        color: white;
        position: relative;
        right: 16vw;
    }

    .time-header-entrance {
        display: inline-block;
        width: 25%;
    }

    .time-header-exit {
        display: inline-block;
        width: 25%;
    }

    .time {
        color: white;
        font-size: 3vw;
        position: relative;
        right: 2vw;
        margin-bottom: 1vw;
    }

    .city {
        display: inline-block;
        width: 31%;
        font-weight: bold;
    }

    .entrance {
        display: inline-block;
        width: 25%;

    }

    .exit {
        display: inline-block;
        width: 25%;
    }
}

