.title {
    font-size: 3.1vw;
    font-weight: bold;
    color: white;
    position: relative;
    top: 0.3vw;
    right: 0.5vw;
    margin-bottom: 2.7vw;
}

.parasha {
    font-size: 4.1vw;
    font-weight: bold;
    color: #fcda70;
    margin-bottom: 3vw;
}

.time-header {
    color: white;
    position: relative;
    right: 16vw;
}

.time-header-entrance {
    display: inline-block;
    width: 25%;
}

.time-header-exit {
    display: inline-block;
    width: 25%;
}

.time {
    color: white;
    font-size: 1.2vw;
    position: relative;
    right: 2vw;
    margin-bottom: 1vw;
}

.city {
    display: inline-block;
    width: 31%;
    font-weight: bold;
}

.entrance {
    display: inline-block;
    width: 25%;

}

.exit {
    display: inline-block;
    width: 25%;
}

@media only screen and (max-width: 768px) {
    .title {
        font-size: 7vw;
        font-weight: bold;
        color: white;
        position: relative;
        top: 0.3vw;
        right: 0.7vw;
        margin-bottom: 4vw;
    }

    .parasha {
        font-size: 9vw;
        font-weight: bold;
        color: #fcda70;
        margin-bottom: 8vw;
    }

    .time-header {
        color: white;
        position: relative;
        right: 16vw;
    }

    .time-header-entrance {
        display: inline-block;
        width: 25%;
    }

    .time-header-exit {
        display: inline-block;
        width: 25%;
    }

    .time {
        color: white;
        font-size: 3vw;
        position: relative;
        right: 2vw;
        margin-bottom: 1vw;
    }

    .city {
        display: inline-block;
        width: 31%;
        font-weight: bold;
    }

    .entrance {
        display: inline-block;
        width: 25%;

    }

    .exit {
        display: inline-block;
        width: 25%;
    }
}
