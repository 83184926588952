@import url('https://fonts.googleapis.com/css2?family=Alef&display=swap');

body {
    font-family: 'Alef', sans-serif;
}

.app-container {
    min-height: 650px;
    overflow-x: auto;
}

.logo-image{
    width: 220px;
}

.shabbat-times-container {
    position: absolute;
    top: 20%;
    left: 15%;
}

.logo-container {
    position: absolute;
    top: 13%;
    right: 23%;
    text-align: center;
}

.download-android {
    color: white;
}

.bottom-container {
    position: absolute;
    bottom: 8%;
    text-align: center;
    width: 100%;
}

.hebdate {
    font-size: 3vw;
    color: #ffdec1;
}

.todayDateTitle {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 15px;
}

.gregDate {
    font-size: 2.4vw;
    position: absolute;
    right: 40%;
    color: white;
}

@media only screen and (max-height: 500px) {
    .bottom-container {
        position: fixed;
        top: 3%;
        right: 33%;
    }
}

@media only screen and (max-width: 768px) {
    .shabbat-times-container {
        position: static;
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 40px;
    }

    .logo-image {
        width: 100px;
    }

    .logo-container {
        position: static;
        text-align: center;
        display: inline-block;
        width: 100%;
        margin-bottom: 50px;
    }

    .download-android {
        color: white;
    }

    .bottom-container {
        position: static;
        /*bottom: 8%;*/
        text-align: center;
        width: 100%;
    }

    .hebdate {
        font-size: 6vw;
        color: #fcda70;
    }

    .gregDate {
        font-size: 5vw;
        position: absolute;
        right: 40%;
        color: white;
    }
    .bottomMenu {
        visibility: hidden;
    }
    body {
        background-image: linear-gradient(to right top, #d16ba5, #d570a9, #d975ad, #de7ab1, #e27fb5, #e77eba, #ec7dc0, #f17cc6, #f674d1, #fa6ddd, #fb65ea, #fb5ff9);        /*background-image: url("assets/bg.jpg");*/

        /*background-position: center center;*/

        /*background-repeat: no-repeat;*/

        /*background-attachment: fixed;*/

        /*background-size: auto;*/
    }
}
