body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*!* Location of the image *!*/
  /*background-image: url("assets/bg.jpg");*/

  /*!* Background image is centered vertically and horizontally at all times *!*/
  /*background-position: center center;*/

  /*!* Background image doesn't tile *!*/
  /*background-repeat: no-repeat;*/

  /*!* Background image is fixed in the viewport so that it doesn't move when*/
  /*   the content's height is greater than the image's height *!*/
  /*background-attachment: fixed;*/

  /*!* This is what makes the background image rescale based*/
  /*   on the container's size *!*/
  /*background-size: cover;*/

  /*!* Set a background color that will be displayed*/
  /*   while the background image is loading *!*/
  /*background-color: #464646;*/
  background-image: linear-gradient(to right top, #d16ba5, #d570a9, #d975ad, #de7ab1, #e27fb5, #e77eba, #ec7dc0, #f17cc6, #f674d1, #fa6ddd, #fb65ea, #fb5ff9);        /*background-image: url("assets/bg.jpg");*/
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
